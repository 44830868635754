<template>
  <div>
    <SubHeaderBlock />
    <b-spinner v-if="spinnerShow && !$route.params.id" class="spinner" label="Large Spinner" />
    <div class="container">
      <component :is="block" @spinner="spinnerShow = false" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    CarParkBlock: () => ({
      component: import("./components/carPark"),
    }),
    DriversBlock: () => ({
      component: import("./components/drivers"),
    }),
    ExpeditorsBlock: () => ({
      component: import("./components/expeditors"),
    }),
    PurchasesBlock: () => ({
      component: import("./components/purchases"),
    }),
    CounterpartiesBlock: () => ({
      component: import("./components/counterparties"),
    }),
    InfoTransportBlock: () => ({
      component: import("./components/info-transport"),
    }),
    CitiesBlock: () => ({
      component: import("./components/cities"),
    }),
    RegionsBlock: () => ({
      component: import("./components/regions"),
    }),
    ManagersBlock: () => ({
      component: import("./components/manager"),
    }),
    ReportBlock: () => ({
      component: import("./components/report"),
    }),
    ProductsBlock: () => ({
      component: import("./components/products"),
    }),
  },
  data() {
    return {
      spinnerShow: true,
    };
  },
  computed: {
    block() {
      return this.$route.params.type + "-block";
    },
  },
  watch: {
    "$route.params.type"() {
      this.spinnerShow = true;
    },
  },
};
</script>

<style scoped></style>
